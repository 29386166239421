import {
    connect
} from "./common";

import {
    abi
} from "../artifact/Uptick721.json";

const base = require('./base');

//xxl todo get from .evn
let contractAddress = "0x679e2af9c4571c5617fa1df4f3094eb62d90f6ee";
let contractAddressPlatform = "0xd3e379f75d08ba91f632b363f021ceda01d94984";

export function setContractAddress(address, platformAddress) {
    if(address) {
        contractAddress = address;
    }
    if(platformAddress) {
        contractAddressPlatform = platformAddress;
    }
}

export async function transferFrom(toAddress, tokenId) {
    const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }

    let gasSetting = await base.getGasPriceAndGasLimit();
    let result = await contract.transferFrom(
        fromAddress, toAddress, tokenId,
        { gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit }
    );
    return result;


}

export async function isApprovedForAll() {
    const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let result = await contract.isApprovedForAll(
        fromAddress, contractAddressPlatform
    );
    return result;
}

export async function setApprovalForAll() {
    const account = await base.getAccounts();
    // const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let result = await contract.setApprovalForAll(
        contractAddressPlatform, true
    );
    return result;
}
