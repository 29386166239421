import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

Vue.use(VueRouter);
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
   return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
   if (onResolve || onReject)
      return originalReplace.call(this, location, onResolve, onReject);
   return originalReplace.call(this, location).catch(err => err);
};

const routes = [
   {
      path: "/",
      redirect: '/index',
   },
   {
      path: "/index",
      name: "Home",
      component: Home,
   },
   {
      path: "/launchpad",
      name: "LaunchPad",
      component: () => import("@/views/marketplace/index.vue"),
   },
   {
      path: "/marketplace",
      name: "Marketplace",
      component: () => import("@/views/resale/index.vue"),
   },
   {
      path: "/promo",
      name: "Promo",
      component: () => import("@/views/Promo/index.vue"),
   },
   {
      path: '/setting',
      name: 'Setting',
      component: () => import('../views/frame/setting.vue')
   },
   {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/detail/index.vue"),
   },
   {
      path: '/follows',
      name: 'Follows',
      component: () => import('../views/frame/follows.vue')
   },
   {
      path: '/fans',
      name: 'Fans',
      component: () => import('../views/frame/fans.vue')
   },
   {
      path: '/auctionlist',
      name: 'Auctionlist',
      component: () => import('../views/frame/auction.vue')
   },
   {
      path: '/creatorlist',
      name: 'Creatorlist',
      component: () => import('../views/frame/creatorNew.vue')
   },
   {
      path: '/saledetail',
      name: 'Saledetail',
      component: () => import('../views/frame/saledetails.vue')
   },
   {
      path: '/salecopy',
      name: 'Salecopy',
      component: () => import('../views/frame/salecopy.vue')
   },
   {
      path: '/otherpage',
      name: 'Otherpage',
      component: () => import('../views/frame/otherpage.vue')
   },
   {
      path: '/carddetail',
      name: 'Carddetail',
      component: () => import('../views/frame/carddetail.vue')
   },
   {
      path: '/create',
      name: 'Create',
      component: () => import('../views/frame/create.vue')
   },
   {
      path: '/createnft',
      name: 'CreateNft',
      component: () => import('../views/frame/createNft.vue')
   },
   {
      path: '/collect',
      name: 'Collect',
      component: () => import('../views/frame/collect.vue')
   },
   {
      path: '/myInfo',
      name: 'MyInfo',
      component: () => import('../views/frame/myInfo.vue')
   },
   // {
   //    path: '/collection_page',
   //    name: 'CollectionPage',
   //    component: () => import("@/views/frame/collectionPage.vue")
   // },
   {
      path:'/collection/:id/:source/:owner',
      name:'CollectionPage',
      component: () => import("@/views/frame/collectionPage.vue")
   },
   {
      path:'/collection_create/:id/:source/:owner',
      name:'CollectionPage_create',
      component: () => import("@/views/frame/collectionPage.vue")
   },
   {
      path: '/pointsCenter',
      name: 'PointsCenter',
      component: () => import('../views/pointsCenter/index.vue')
   },
   {
      path: '/claimHistroy',
      name: 'ClaimHistroy',
      component: () => import('../views/pointsCenter/claimHistroy.vue')
   },
   {
      path: '/myOrders',
      name: 'MyOrders',
      component: () => import('../views/frame/myOrders.vue')
   },
   {
      path: '/officialAirdrop',
      name: 'OfficialAirdrop',
      component: () => import('../views/airDrop/officialAirdrop.vue')
   },
  
   {
      path: '/message',
      component: () => import('../views/message/index.vue'),
      children: [
         {
            path: '',
            name: 'Message',
            component: () => import('../views/message/UserMessage.vue')
         },
         {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('../views/message/Notifications.vue')
         },
         {
            path: 'system-alert',
            name: 'SystemAlert',
            component: () => import('../views/message/SystemAlert.vue')
         },
      ]
   },
   {
      path: '/personal-page',
      name: 'PersonalPage',
      component: () => import('../views/personalPage/index.vue'),
   },
   {
      path: '/personal-pagecopy',
      name: 'PersonalPageCopy',
      component: () => import('../views/personalPage/indexcopy.vue'),
   },
   {
      path: '/helpcenter',
      name: 'HelpCenter',
      component: () => import('../layout/components/HelpPage.vue'),
   },
   {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../layout/components/privacy_en.vue'),
   },
   {
      path: '/term',
      name: 'Term',
      component: () => import('../layout/components/term_en.vue'),
   },
   {
      path: '/privacy_zh',
      name: 'Privacyzh',
      component: () => import('../layout/components/privacy_zh.vue'),
   },
   {
      path: '/term_zh',
      name: 'Termzh',
      component: () => import('../layout/components/term_zh.vue'),
   },
   {
      path: '/auctionrule_zh',
      name: 'Auctionrulezh',
      component: () => import('../assets/html/AuctionRules_zh'),
   },
   {
      path: '/auctionrule_en',
      name: 'Auctionruleen',
      component: () => import('../assets/html/AuctionRules_en'),
   },
];



const router = new VueRouter({
  mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition
      } else {
         return { x: 0, y: 0 }
      }
   }
});

export default router;
