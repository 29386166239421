import Vue from "vue";
import unlockDlg from "./UnlockDlg.vue";

let instance;

let UnlockDlg = function(opt) {
    if(instance) {
        return instance;
    }
    const UnlockDlg = Vue.extend(unlockDlg);
    instance = new UnlockDlg({
        propsData: opt  // to page props
    });
    instance.parent = instance;
    instance.vm = instance.$mount();
    return instance;
}

export function unlockShow(opt) {
    const ins = UnlockDlg(opt);
    document.body.appendChild(ins.$el);
}

export function unlockhide() {
    if(!instance) {
        return;
    }
    instance.$el.remove();
    instance.$destroy();
    instance = null;
}

export function init() {
    // window.eventBus.$on("SignatureEvent", hide);
    // function handle(opt) {
    //     if(opt == 0) {
    //         instance.setText("In signature...");
    //     } else {
    //         hide();
    //     }
    // }

    // const UnlockDlg = Vue.extend(unlockDlg);
    // let instance = new UnlockDlg().$mount(document.createElement('div'));
    // document.body.appendChild(instance.$el);
    
    async function show(opt) {
        const ins = UnlockDlg(opt);
        document.body.appendChild(ins.$el);

        if(window.walletType != 3) { 
            return await ins.sign();
        }

        // Vue.nextTick(() => {
        //     instance.show(opt);
        // })
    }
    function hide() {
        if(!instance) {
            return;
        }
        instance.$el.remove();
        instance.$destroy();
        instance = null;
    }
    Vue.prototype.UnlockDlg = {
        show, hide
    };
}

export default init;
