import { wallet as keplrWallet } from "@/keplr";
// import Web3 from 'web3';
// const Web3 = require('web3');
import store from "../store/index.js"
import { getMyBalance } from "../keplr/wallet.js";

export const getkeplaWeb3 = async () => {
	let account = await keplrWallet().getAccountInfo();
	return account.bech32Address
	
};



import { wallet as evmWallet } from "@/metaMask";
export const getMatamaskWeb3 = async () => {
    let account = await evmWallet().getAccountInfo();
    let address = await account.getAddress();
    return address;
};


export const keplrKeystoreChange = async () => {
    
	window.removeEventListener("keplr_keystorechange", keplrKeystoreChange);
	//

	let account = await getkeplaWeb3();
	let balance = await getMyBalance();
	let mount = balance.format

	localStorage.setItem("key_balance", mount);

	let json = window.localStorage.getItem("key_user");
	let data = JSON.parse(json);
	let accounts = data.did

	localStorage.setItem("key_user", JSON.stringify({ user: true, did: account }));

	store.commit('SET_DID', account)
    window.bscAddress = data.bscAddress;

	if (account != accounts)
		location.reload();
}
