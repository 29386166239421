import {
    connect
} from "./common";

import {
    abi
} from "../artifact/ERC1155Platform.json";
import { utils } from "ethers";

const base = require('./base');

//xxl todo get from .evn
let contractAddress = "0x99a415da5b4d061556e2d55c3382cfeda02a5a7d"

export function setContractAddress(platformAddress) {
    if(platformAddress) {
        contractAddress = platformAddress;
    }
}

// address _owner,
// uint256 _tokenId,
// uint256 _price,
// uint256 _amount,
// address _to,
// bytes calldata data
export async function transfer(owner, tokenId, value, assetId) {
    const account = await base.getAccounts();
    const address = await account.getAddress();
    
    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }

    let data = JSON.stringify({ assetId: [assetId] });
    let amount = 1;
    let gasSetting = await base.getGasPriceAndGasLimit();

    let rep = await contract.transfer(owner, tokenId, value, amount, address, utils.toUtf8Bytes(data), {
        value: value, gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit
    });
    return rep;
}

export async function saveApprove(tokenArr, offAssetIds, value, fee) {
    const account = await base.getAccounts();
    // const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let data = JSON.stringify({ assetId: offAssetIds });
    let amount = offAssetIds.length;
    let gasSetting = await base.getGasPriceAndGasLimit();

    let rep = await contract.saveApprove(tokenArr[0], value, amount, utils.toUtf8Bytes(data), {
        value: fee, gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit
    });

    return rep;
}

export async function revokeApprove(tokenArr, onAssetIds, value) {
    const account = await base.getAccounts();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let data = JSON.stringify({ assetId: onAssetIds });
    let amount = onAssetIds.length;
    let gasSetting = await base.getGasPriceAndGasLimit();
    let rep = await contract.revokeApprove(tokenArr[0], value, amount, utils.toUtf8Bytes(data),
        { gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit }
    );
    return rep;
}
