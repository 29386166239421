import Vue from "vue";
import {
    getMyBalance as iris_getMyBalance,
    issueDenomAndMint as iris_issueDenomAndMint,
    transferNft as iris_transferNft,
    orderPay as iris_orderPay,
    getAccountInfo as iris_getAccountInfo
} from '@/keplr/wallet';

import {
    getMyBalance as uptick_getMyBalance,
    issueDenomAndMint as uptick_issueDenomAndMint,
    transferNft as uptick_transferNft,
    orderPay as uptick_orderPay,
    getAccountInfo as uptick_getAccountInfo
} from '@/keplr/uptick/wallet';

// import {
//     getMyBalance as uptickevm_getMyBalance,
//     // issueDenomAndMint as uptickevm_issueDenomAndMint,
//     // transferNft as uptickevm_transferNft,
//     // orderPay as uptickevm_orderPay,
//     getAccountInfo as uptickevm_getAccountInfo
// } from '@/metaMask/evm/index';

import chain_iris_w from "@/assets/icons/chain_iris_w.png";
import chain_uptick_w from "@/assets/icons/chain_uptick_w.png";

let chainName = "";

export function wallet() {
    let wt = {};

    if (chainName == "IRIS") {
        wt = {
            getMyBalance: iris_getMyBalance,
            issueDenomAndMint: iris_issueDenomAndMint,
            transferNft: iris_transferNft,
            orderPay: iris_orderPay,
            getAccountInfo: iris_getAccountInfo
        };
    } 
    else if (chainName == "UPTICK") {
        wt = {
            getMyBalance: uptick_getMyBalance,
            issueDenomAndMint: uptick_issueDenomAndMint,
            transferNft: uptick_transferNft,
            orderPay: uptick_orderPay,
            getAccountInfo: uptick_getAccountInfo
        };
    }
    return wt;
};

export function init(chain) {
    if(chain) chainName = chain;
    Vue.prototype.$chainName = chain;
    
    let unit = chain=="IRIS" ? "IRIS":
               chain=="UPTICK" ? "UPTICK":
               chain=="UPTICK EVM" ? "UPTICK":
               "";
    Vue.prototype.$walletUnit = unit;

    let img = chain=="IRIS" ? chain_iris_w:
              chain=="UPTICK" ? chain_uptick_w:
              chain=="UPTICK EVM" ? chain_uptick_w:
              "";
    Vue.prototype.$walletIcon = img;

    Vue.prototype.$wallet = wallet(chain);
    Vue.filter("$unitFormat", function(val) {
        return val.replaceAll("$UNIT", unit);
    });
}
