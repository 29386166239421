// import Vue from "vue";
import Web3 from 'web3'
const md5 = require('md5');
const web3Obj = new Web3();
let base = require('./handler/base.js');
let uptick721 = require('./handler/uptick721.js');
let uptick1155 = require('./handler/uptick1155.js');
const erc721Platform = require("./handler/erc721Platform.js");
const erc1155Platform = require("./handler/erc1155Platform.js");

import $store from "../../store";

(function () {
    if (window.ethereum) {
        base.addNetwork();
        init();
    }
})();

async function handleAccountsChanged(accounts) {
    if (accounts.length == 0) return;
    sessionStorage.removeItem("KEY_LRCAccount");

    let address = accounts[0];
    // let did = getUptickAddress(address.substr(2));
    let did = address;
    $store.commit('SET_DID', did.toLowerCase());
    // address = address.toLowerCase();
    // window.bscAddress = address;
    localStorage.setItem("key_user", JSON.stringify({ user: true, did: $store.state.did, bscAddress: "", walletType: 2 }));
    location.reload();
}

export async function getMyBalance() {
    let balance = { amount: await base.getBalance() };
    let amt = web3Obj.utils.fromWei(balance["amount"], "ether");

    balance["format"] = parseFloat(amt).toFixed(2);
    let mount = balance.format;

    localStorage.setItem("key_balance", mount);
    return balance;
}

export async function getAccountInfo() {
    let account = await base.getAccounts();
    return account;
}

export function getUptickAddress(evmAddress) {
    return base.getUptickAddress(evmAddress);
}

export async function transfer(
    orgName, sender, data, fee, nftType
) {
    let name = getDenomName(orgName, sender);
    let id = getDenomId(name)
    let toAddress = nftType=="ERC721"?window.$env.VUE_APP_RECIPIENT_ERC721:
                    nftType=="ERC1155"?window.$env.VUE_APP_RECIPIENT_ERC1155:"";
    let result = await base.transfer(toAddress, fee, data);
    return [result, name, id];
}
function getDenomName(name, address) {

    let denomName = name + "_" + Math.floor(Date.now() / 1000) + "_" + address.substr(address.length - 4);
    return denomName;

}

function getDenomId(name) {

    let preFix = process.env.VUE_APP_PREFIX;
    return preFix + md5(name);

}


export async function orderPay(nftType, recipient, tokenId, fee, assetId) {
    fee = web3Obj.utils.toWei(fee.toString());
    if(nftType == "ERC721") {
        let result = await erc721Platform.transfer(recipient, tokenId, fee);
        return result;
    }
    else if(nftType == "ERC1155") {
        let result = await erc1155Platform.transfer(recipient, tokenId, fee, assetId);
        return result;
    }
}

export async function saveApproveWithArray(nftType, tokenArr, value, fee, offAssetIds) {
    if(nftType == "ERC721") {
        let isApproved = await uptick721.isApprovedForAll();
    
        if (!isApproved) {
            let setApproval = await uptick721.setApprovalForAll();
        }
    
        value = web3Obj.utils.toWei(value.toString());
        fee = web3Obj.utils.toWei(fee.toString());
        let result = await erc721Platform.saveApproveWithArray(tokenArr, value, fee);
        return result;
    }
    else if(nftType == "ERC1155") {
        let isApproved = await uptick1155.isApprovedForAll();
    
        if (!isApproved) {
            let setApproval = await uptick1155.setApprovalForAll();
        }
        value = web3Obj.utils.toWei(value.toString());
        fee = web3Obj.utils.toWei(fee.toString());
        let result = await erc1155Platform.saveApprove(tokenArr, offAssetIds, value, fee);
        return result;
    }
    
}

export async function revokeApprovesWithArray(nftType, tokenArr, value, onAssetIds) {
    if(nftType == "ERC721") {
        let result = await erc721Platform.revokeApprovesWithArray(tokenArr);
        return result;
    }
    else if(nftType == "ERC1155") {
        value = web3Obj.utils.toWei(value.toString());
        let result = await erc1155Platform.revokeApprove(tokenArr, onAssetIds, value);
        return result;
    }
}

export async function transferFrom(nftType, toAddress, tokenId, assetIds) {
    // let mint = await uptick721.mint(tokenId, memo);
    if (nftType == "ERC721") {
        let transferFrom = await uptick721.transferFrom(toAddress, tokenId);
    
        return transferFrom;
    }
    else if (nftType == "ERC1155") {
        let transferFrom = await uptick1155.safeTransferFrom(toAddress, tokenId, assetIds);
    
        return transferFrom;
    }
}

// address, platformAddress
export function setContractAddress(nftType, addressObject) {
    if (nftType == "ERC721") {
        uptick721.setContractAddress(addressObject.address, addressObject.platformAddress);
        erc721Platform.setContractAddress(addressObject.platformAddress);
    }
    else if (nftType == "ERC1155") {
        uptick1155.setContractAddress(addressObject.address, addressObject.platformAddress);
        erc1155Platform.setContractAddress(addressObject.platformAddress);
    }
}

export function init() {
    let json = window.localStorage.getItem("key_user");
    if(json && window.walletType==2) {
        window.ethereum.on('accountsChanged', handleAccountsChanged);
    }
}
