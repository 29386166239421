<template>
<div>
   <div class="root-home" v-if="!isMobile">
    <img class="image" src="@/assets/image/image1.jpg" />
    <div class="bg_jb_w"></div>
    <div class="content">
      <div class="left">
        <div class="tit">
          Explore & Collect. Mint & Trade. All in one NFT world.
        </div>
        <div class="desc">
          Uptick on Loopring is the open NFT marketplace powered by Uptick
          Network and Loopring. It builds the secure, fast, and cheap NFT
          ecosystem on Ethereum zkRollup Layer2.
        </div>
        <div class="btn">
          <button class="explore" @click="exploreClick">Explore</button>
          <button class="create" @click="createClick">Create</button>
        </div>
      </div>
      <div class="right">
        <img class="img" src="@/assets/image/image1.jpg" />
      </div>
      <div class="right_bottom" v-if="!isMobile">
        <img
          v-if="!openmsg_group"
          src="@/assets/image/btn_service-message.png"
          @click="openServiceMessage"
          alt=""
        />
      </div>
      <div v-if="openmsg_group" style="position: fixed; right: 20px; bottom: 20px; z-index: 9999;">
         <message-service @getOpen="OpenMsg_group" v-if="openmsg_group" :currfriend="currfriend_group"></message-service>
      </div> 
    </div>
    <uComponents ref="ucom"></uComponents>
  </div>
  <div class="mobile-home" v-else>
    <img class="img" src="@/assets/image/image1.jpg" />
    <div class="content">
      <div class="tit">
        Explore & Collect. Mint & Trade. All in one NFT world.
      </div>
      <div class="desc">
        Uptick on Loopring is the open NFT marketplace powered by Uptick Network
        and Loopring. It builds the secure, fast, and cheap NFT ecosystem on
        Ethereum zkRollup Layer2.
      </div>
      <div class="btn">
        <button class="explore" @click="exploreClick">Explore</button>
      </div>
    </div>
   
  </div>
   <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
</div>
 
  
</template>

<script>
import MessageService from "@/components/popup/messageService.vue";
import api from "@/api"
import Banprompt from "@/components/popup/banprompt.vue";
	 import { getSettingArr } from "@/utils/helper";
export default {
  
  name: "App",
  components: {MessageService,Banprompt},
  data: function () {
    return {
      Banprompt:false,
       currfriend_group:{},
    openmsg_group: false,
    isShowCreate:'',
    isDeposit:''

    };
  },
  methods: {
     async showNoRead(){
       console.log("2222222222222222222")
    const json = localStorage.getItem("key_user");
    if (json) {
           let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContent,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
      openServiceMessage(){
      console.log("openServiceMessage")
        this.currfriend_group.fansAddress = "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
        this.currfriend_group.fansName = "Uptick CS"
        this.openmsg_group = true;

    },
    			 openBanprompt(e){
         this.Banprompt = e
      },
     OpenMsg_group(e) {
        this.openmsg_group = e;
      },
    exploreClick() {
       this.$gtag.event('首页-Explore', { 'event_category': 'Click', 'event_label': 'Explore' })
      this.$router.push({ name: "LaunchPad" });
    },
    async createClick() {
      this.$gtag.event('首页-Create', { 'event_category': 'Click', 'event_label': 'Create ' })
        if (!this.$store.state.did) {
        window.eventBus.$emit("ShowLogoBox");
        return;
      }
       
      let isLogged = await this.getLogged();
      if(isLogged && isLogged.showModal == false) {
        this.UnlockDlg.hide();
        // if(isLogged.result.method == "personal_sign") {

        // } 
      }
      else {
        if (!isLogged) {
          let accInfo = await this.UnlockDlg.show();
          if (!accInfo || !accInfo.accountId) {
            this.UnlockDlg.hide();
            return;
          }
          this.UnlockDlg.hide();
        }
      }
       let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)
    
      if(this.isShowCreate && this.isDeposit == 3 && jsonObj.grade != 2 )
      {
        this.$router.push({ name: "Create" });
      }else{
        this.Banprompt = true
      }
      
      
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    this.showNoRead();
     this.isShowCreate = sessionStorage.getItem("showCreate");
    this.isDeposit = sessionStorage.getItem("isDeposit");
  },
};
</script>

<style lang="scss" scoped>
.root-home {
  position: absolute;
  left: 0;
  right: 0;

  .image {
    width: 100%;
    height: 600px;
    opacity: 0.1;
  }

  .bg_jb_w {
    position: fixed;
    top: 390px;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: aquamarine;
    background-image: url(../../assets/image/bg_jb_w.png);
    background-size: 100% 100%;
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 90px;
    // background-color: burlywood;
    display: flex;
    justify-content: center;

    .left {
      .tit {
        width: 630px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 45px;
        letter-spacing: 1px;
        color: #270645;
        margin-top: 30px;
      }

      .desc {
        width: 600px;
        font-family: Helvetica;
        font-size: 24px;
        letter-spacing: 1px;
        color: #270645;
        margin-top: 10px;
      }

      .btn {
        width: 400px;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .explore {
          width: 181px;
          height: 51px;
          background-color: #270645;
          border-radius: 25px;
          font-family: Helvetica;
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
        }

        .create {
          width: 181px;
          height: 51px;
          background-color: #1d42ff;
          border-radius: 25px;
          font-family: Helvetica;
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    .right {
      margin-left: 100px;

      .img {
        width: 451px;
        height: 381px;
        background-color: #270645;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .right_bottom {
      position: fixed;
      bottom: 100px;
      right: 50px;
      z-index: 200;

      img {
        display: block;
        width: 59px;
        height: 59px;
        margin-top: 35px;
      }
    }
  }
}

.mobile-home {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .img {
    height: 330px;
    object-fit: cover;
  }

  .content {
    .tit {
      width: 100%;
      font-family: Helvetica;
      font-weight: bold;
      font-size: 35px;
      letter-spacing: 1px;
      color: #270645;
      margin-top: 30px;
    }

    .desc {
      width: 100%;
      font-family: Helvetica;
      font-size: 20px;
      letter-spacing: 1px;
      color: #270645;
      margin-top: 10px;
    }

    .btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 30px 0 40px 0;

      .explore {
        width: 100%;
        height: 51px;
        background-color: #270645;
        border-radius: 25px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}

.clearpos {
  position: static;
}
</style>
