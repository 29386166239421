<template>
    <v-app :class="{ 'drawer-opened': drawerOpened }" >
        <Header v-model="drawerOpened" :containerId="containerId" style="position:sticky;top:0;" />
        <v-main v-show="!drawerOpened">
            <v-container :id="containerId" class="content">
                <router-view :key="key"></router-view>
            </v-container>
        </v-main>
        <Footer v-if="!drawerOpened" />
    </v-app>
</template>

<script>
    import Header from './Header.vue';
    import Footer from './Footer.vue';

    export default {
        name: 'Layout',
        components: {
            Header,
            Footer
        },
        data: function() {
            return {
                drawerOpened: false,
                containerId: 'container'
            };
        },
        computed: {
            key() {
                return this.$route.name !== undefined ?
                    this.$route.name + new Date() :
                    this.$route + new Date();
            }
        },
        beforeMount() {
            this.$store.dispatch('setInfo');
        }
    };
</script>

<style lang="scss" scoped>
    .drawer-opened {
        height: 100vh;
    }

    .content {
        margin: 0 auto;
        padding: 0;
        width: calc(100% - 40px);
        min-height: 550px;

        @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
            width: 1220px;
            min-height: 550px;
        }
    }
</style>

<style>
    .width-100 {
        display: inline-block !important;
        width: 100% !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-18 {
        display: inline-block !important;
        width: 180px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .width-8 {
        display: inline-block !important;
        width: 80px !important;
        height: auto !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis !important;
    }

    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
    .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
    .theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
        color: #766983 !important;
    }
</style>
