import { ethers } from "ethers";
let { bech32 } = require('bech32');
import Web3 from 'web3';
const web3Obj = new Web3("https://peer1.testnet.uptick.network:8645");

const fromHexString = hexString =>
  new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

export async function getBalance() {
    const account = await getAccounts();
    let address = await account.getAddress();
    let amt = web3Obj.eth.getBalance(address);
    return amt;
}

export async function getAccounts(){
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts",[]);
    let signer = provider.getSigner();
    //console.log(await signer.getAddress());
    return signer;
}

export function getUptickAddress(evmAddress){

    //xxl TODO from evm
    //let preFix = "uptick"
    //let uptickAddress = Base58.encode(fromHexString(evmAddress));

    let words = bech32.toWords(fromHexString(evmAddress));
    return bech32.encode('uptick', words)

    //return preFix + uptickAddress;
}

export async function addNetwork() {
    try {
        // await window.ethereum.request({
        //     method: "wallet_switchEthereumChain",
        //     params: [{ chainId: '0x5' }]


        //     // method: 'wallet_addEthereumChain',
        //     // params: [{
        //     //     chainId: '0x1e60',
        //     //     chainName: 'Uptick Testnet',
        //     //     nativeCurrency: {
        //     //         name: 'UptickToken',
        //     //         symbol: 'Uptick', // 2-6 characters long
        //     //         decimals: 18,
        //     //     },
        //     //     // rpcUrls: ['http://47.89.185.2:8545'],
        //     //     rpcUrls: ['https://peer0.testnet.uptick.network:8645'],
        //     //     blockExplorerUrls: ['https://evm-explorer.testnet.uptick.network/']
        //     // }],
        // });
    }
    catch (error) {
        console.log(error);
    }
}

const { utils } = require('ethers')
export async function transfer(toAddress, value, memo) {
    let hexValue = utils.parseEther(value).toHexString();
    //xxl TODO read from .env
    // const toAddress2 = process.env.VUE_APP_AUPTICK_PREFIX;
    const gasPrice = '0x2540be400'
    const gas =  '0x7a1200'
    
    const account = await getAccounts();
    let address = await account.getAddress();

     const transactionParameters = {
       gasPrice,gas,to:toAddress,from: address, value: hexValue,data:memo, 
     };
     
     // txHash is a hex string
     // As with any RPC call, it may throw an error
     const txHash = await window.ethereum.request({
       method: 'eth_sendTransaction',
       params: [transactionParameters],
     });
     return txHash
     //console.log(txHash);
}

// var Web3 = require('web3');
export async function getGasPriceAndGasLimit(){

     //xxl TODO setting from .evn
    // let  web3Obj = new Web3('https://seed0.testnet.uptick.network:8645');

    // let gasPrice = await web3Obj.eth.getGasPrice()
    // if (typeof(gasPrice) == undefined){
    //     gasPrice = "0x2540be400";
    // }else{
    //     gasPrice = gasPrice * 1000000000
    // }
    
    let gasPrice = 10000000000;
    //xxl TODO setting from .evn
    let gasLimit = "0x7a1200";


    return {gasPrice,gasLimit};

}