import   {$postThird } from '@/utils/request';
// import service from "@/utils/request"
import { BASE_IMAGE_URL 
} from '../config';


export function uploadImage(file, onUploadProgress) {
    
    
    const formData = new FormData();
    formData.append('image', file);

    //xxl post third
    return $postThird('/uptickapi/ipfs/upload/image', formData, {
        headers: {
            'Content-Type': 'application/octet-stream',
            accessKey: 'uptick',
            accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229'
        },
        onUploadProgress
    })
}

//图片显示
export function getNftImg({
    hash,
    size = 'small'
}) {
    

    // let url = "http://file.uptick.world/v2/image/url";

    // return $postThird(url, undefined, {
    //     params: { hash, size },
	// 	headers: {
	// 		accessKey: 'uptick',
	// 		accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
	// 	},
	// });
    return $postThird('/image', undefined, {
        params: { hash, size },
		headers: {
			accessKey: 'uptick',
			accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
		},
	});

    // return $postThird('/image', undefined, {
    //     params: { hash, size },
	// 	headers: {
	// 		accessKey: 'uptick',
	// 		accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
	// 	},
	// });

};



