import {
    connect
} from "./common";

import {
    abi
} from "../artifact/Uptick1155.json";
import { utils } from "ethers";

const base = require('./base');

//xxl todo get from .evn
let contractAddress = "0xF4FD5200f7fFa79E910FdFa22549fCEB3a530206"
let contractAddressPlatform = ""

export function setContractAddress(address, platformAddress) {
    if(address) {
        contractAddress = address;
    }
    if(platformAddress) {
        contractAddressPlatform = platformAddress;
    }
}

export async function safeTransferFrom(toAddress, tokenId, assetIds) {
    const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }

    let data = JSON.stringify({ assetId: assetIds });
    let amount = assetIds.length;

    let result = await contract.safeTransferFrom(
        fromAddress, toAddress, tokenId, amount, utils.toUtf8Bytes(data)
    );

    return result;
}

export async function isApprovedForAll() {
    const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let result = await contract.isApprovedForAll(
        fromAddress, contractAddressPlatform
    );
    return result;
}

export async function setApprovalForAll() {
    const account = await base.getAccounts();
    // const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let result = await contract.setApprovalForAll(
        contractAddressPlatform, true
    );
    return result;
}
