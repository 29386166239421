<template>
<div class="foot">
   <v-footer
    app
    absolute
    class="pa-0 pb-5 pb-lg-5 d-flex flex-column"
    color="#1d42ff"
    :elevation="0"
  >
    <v-container
      class="
        d-flex
        flex-column flex-lg-row
        align-center
        py-0
        px-10 px-lg-0
        mt-lg-5
      "
    >
     
      <template v-if="isMobile">
        <div style="margin-top:30px">
          <Logo height="43px" />
        </div>
         
        <div class="d-flex justify-space-around mt-8" style="width: 100%">
          <a :href="`http://${mediumurl}`" target="_blank"  @click="mediaClick('medium')">
            <img
              src="@/assets/icons/icon_medium.png"
              style="width: 30px; height: 30px"
            />
          </a>
          <a :href="twitterurl" target="_blank" @click="mediaClick('twitter')">
            <img
              src="@/assets/icons/icon_twitter.png"
              class="ml-8"
              style="width: 30px; height: 30px"
            />
          </a>
          <a :href="teleurl" target="_blank" @click="mediaClick('telegram')">
            <img
              src="@/assets/icons/icon_telegram.png"
              class="ml-8"
              style="width: 30px; height: 30px"
            />
          </a>
          <a :href="`mailto:${mailurl}`" target="_blank" @click="mediaClick('email')">
            <img
              src="@/assets/icons/icon_mail.png"
              class="ml-8"
              style="width: 30px; height: 30px"
            />
          </a>
        </div>
      </template>
      <template v-else>
         <Logo height="43px" />
        <v-spacer></v-spacer>
        <!-- <span class="text--primary">hello@uptickproject.com</span> -->
        <div class="about d-flex flex-row" style="font-weight:bold">
          <a href="https://www.uptick.network/#/" target="_blank" @click="moreClick('about')" style="font-weight:bold">{{$t('FooterAbout')}}</a>
          <a class="ml-10" href="https://blog.uptickproject.com/" @click="moreClick('blog')" target="_blank" style="font-weight:bold">{{$t('FooterBlog')}}</a>
           <div class=" pointer ml-10" @click="toPrivacy">{{$t('FooterPrivacyPolicy')}}</div>
           <div class="pointer ml-10" @click="toTerms">{{$t('FooterTermsService')}}</div>
        </div>
         
           <a :href="`mailto:${mailurl}`" target="_blank"  @click="mediaClick('email')" >
            <img
              src="@/assets/icons/icon_mail.png"
              class="ml-8"
              style="width: 28px; height: 28px"
            />
          </a>
        <a :href="`https://${mediumurl}`" target="_blank " @click="mediaClick('medium')">
          <img
            src="@/assets/icons/icon_medium.png"
            class="ml-8"
            style="width: 30px; height: 30px"
          />
        </a>
        <a :href="twitterurl" target="_blank" @click="mediaClick('twitter')">
          <img
            src="@/assets/icons/icon_twitter.png"
            class="ml-7"
            style="width: 30px; height: 30px"
          />
        </a>
        <a :href="teleurl" target="_blank"  @click="mediaClick('telegram')">
          <img
            src="@/assets/icons/icon_telegram.png"
            class="ml-6"
            style="width: 30px; height: 30px"
          />
        </a>
      </template>
    </v-container>
    <!-- <v-container>
       <template  v-if="!isMobile">
          <div class="fotnav d-flex justify-space-between mt-5">
      <div class="d-flex flex-column left">
        <div class="mb-lg-5">
          <a href="https://www.uptick.network/#/" target="_blank">{{$t('FooterAbout')}}</a>
        </div>
        <div class="mb-lg-5">
          <a href="https://blog.uptickproject.com/" target="_blank">{{$t('FooterBlog')}}</a>
        </div>
    
      </div>
      <div class="d-flex flex-column right">
        <div class="mb-lg-4 pointer" @click="toPrivacy">{{$t('FooterPrivacyPolicy')}}</div>
        <div class="pointer" @click="toTerms">{{$t('FooterTermsService')}}</div>
      </div>
    </div>
       </template>
       <template v-else>
          <div class="fotmobile d-flex justify-space-around mt-5">
      <div class="d-flex flex-column base-box">
        <div class="top-item">
          <a href="https://www.uptick.network/#/" target="_blank">{{$t('FooterAbout')}}</a>
        </div>
        <div class="top-item">
          <a href="https://blog.uptickproject.com/" target="_blank">{{$t('FooterBlog')}}</a>
        </div >
      

        <div class="bottom-item" @click="toPrivacy">
          {{$t('FooterPrivacyPolicy')}}
        </div>
        <div class="last-item" @click="toTerms">
          {{$t('FooterTermsService')}}
        </div>
        
      </div>

    </div>
       </template>
    </v-container> -->
  </v-footer>
</div>
 
</template>

<script lang="js">
  import Logo from "@/components/footLogo/index.vue";

  export default {
    name: "Footer",
    components: { Logo },
    props: {},
    data: function() {
      return {
        mediumurl: "uptickproject.medium.com",
        twitterurl:'https://twitter.com/Uptickproject',
        teleurl:'https://t.me/uptickproject',
        mailurl:'hello@uptickproject.com'
      };
    },
    computed: {
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
       mediaClick(name){
         this.$gtag.event('底部导航-'+name, { 'event_category': 'Click', 'event_label': name })
        console.log("mediaClick",name);
      },
       moreClick(name){
 this.$gtag.event('底部导航-'+name, { 'event_category': 'Click', 'event_label': name })
      },
      openmedium() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentwitter() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      opentelegram() {
        window.open().opener = null;
        window.location.href = this.url;
      },
      toHelp(){
        this.$router.push({name:'HelpCenter'})
      },
      toPrivacy(){
        this.$gtag.event('底部导航-Privacy', { 'event_category': 'Click', 'event_label': 'Privacy' })
        if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Privacyzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Privacy'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Privacy'})
        }
      },
      toTerms(){
 this.$gtag.event('底部导航-Copyright Notice', { 'event_category': 'Click', 'event_label': 'Copyright Notice' })
          if(this.$vuetify.lang.current == 'en'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'zh'){
          this.$router.push({name:'Termzh'})
        }else if(this.$vuetify.lang.current == 'ja'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'ko'){
          this.$router.push({name:'Term'})
        }else if(this.$vuetify.lang.current == 'pt'){
          this.$router.push({name:'Term'})
        }

      }
    }
  };
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
  font-weight: normal;
  color: #ffffff !important;
}
.pointer {
  color: #ffffff !important;
  cursor: pointer;
}
.fotnav {
  max-width: 1220px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }
}
.fotmobile {
  width: 320px;
  margin: 25px auto 16px;

  .left {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    .pointer {
      cursor: pointer;
    }
  }
  .right {
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #270645;
    text-align: right;
    .pointer {
      cursor: pointer;
    }
  }

  .base-box{
    width: 100%;
    // border: solid 1px red;
    margin-bottom: -40px;
  }

  .top-item{
    // width: 100%;
    // margin: 0,auto;
    font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .bottom-item{
    // width: 100%;
    // margin: 0,auto;
    // font-weight: 600;
    margin-bottom: 20px;
    // border: solid 1px black;
    text-align: center;
  }

  .last-item{
    text-align: center;
  }
}
</style>
