import Vue from "vue";

export const getFileSrc = async (storageKey, hash, isVideo) => {
	// let url = `https://infura-ipfs.io/ipfs/${hash}`;
	const vue = new Vue();
	return vue.getImageIpfs(hash);
	
	// let url=`https://loopring.mypinata.cloud/ipfs/${hash}`
	
	// return url;

    // try{
    // 	const key = `${storageKey}_${hash}`;
    // 	let src = storage.get(key);
    // 	if (src) {
    // 	    return src;
    // 	}
    // 	let response;
    // 	if (isVideo) {
	// 		if(hash)
	// 		{
	// 			response = await api.video.getNftVideo({
	// 				hash
	// 			});
	// 			src =  response.data.data;
	// 			storage.set(key, src);
	// 			return src;
	// 		}
    	   
    // 	} else {
	// 		if(hash)
	// 		{
	// 			response = await api.image.getNftImg({
	// 				hash
	// 			});
	// 			src =  response.data.data;
	// 			storage.set(key, src);
	// 			return src;
	// 		}
    	  
    // 	}
    
    // }catch(e){
    // 	return "";
    // }
};