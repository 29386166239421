import   {$postThird } from '@/utils/request';


export function uploadVideo(file, onUploadProgress) {
    
    
    const formData = new FormData();
    formData.append('video', file);

    //xxl post third
    return $postThird('/upVideo', formData, {
        headers: {
            'Content-Type': 'application/octet-stream',
            accessKey: 'uptick',
            accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229'
        },
        onUploadProgress
    })
}

// export let getNftVideo = (parms) => {
// 	return $postThird('/video', `hash=${parms.hash}&size=${parms.size}`, {
// 		headers: {
// 			accessKey: 'uptick',
// 			accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
// 		},
// 	});
// };

//图片显示
export function getNftVideo({
    hash,
    size = 'small'
}) {
    return $postThird('/video', undefined, {
        params: { hash, size },
		headers: {
			accessKey: 'uptick',
			accessKeySecret: 'AF0F59F1EEC1728CCFCD5B7B6203D229',
		},
	});

};
