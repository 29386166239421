import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import IconFont from "@/components/iconfont";
import i18n from "@/plugins/i18n";

Vue.use(Vuetify);

Vuetify.config.silent = false;

export default new Vuetify({
  icons: {
    values: IconFont,
  },
  lang: {
    t: (key, ...params) => {
      if (key === '$vuetify.noDataText') {
        return ''
      }
      if (key === '$vuetify.timePicker.prevMonthAriaLabel') {
        return 'prev'
      }
      if (key === '$vuetify.timePicker.nextMonthAriaLabel') {
        return 'next'
      }
      if (key === '$vuetify.timePicker.am') {
        return 'am'
      }
      if (key === '$vuetify.timePicker.pm') {
        return 'pm'
      }
      if (key === '$vuetify.dataFooter.itemsPerPageText') {
        return ''
      }
      if (key === '$vuetify.dataFooter.pageText') {
        return ''
      }
      if (key === '$vuetify.dataFooter.itemsPerPageAll') {
        return 'All'
      }
      return i18n.t(key, params);
    },
  },
  breakpoint: {
    thresholds: {
      sm: 640,
      //lg: 1220,
      lg:900,
    },
    //mobileBreakpoint: 1220,
    mobileBreakpoint: 900,
    scrollBarWidth: 24,
  },
  theme: {
    themes: {
      light: {
        primary: "#270645",
        secondary: '#766983',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FB8C00',
      },
      dark: {
        primary: "#fff",
      },
    },
  },
});
