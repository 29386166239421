import { 
    SigningStargateClient,
    coins
} from '@uptsmart/stargate'
//import { SigningStargateClient } from '@/uptick/cosmjs/packages/stargate';
import { 
    CosmWasmClient 
} from "@cosmjs/cosmwasm-stargate";

import { getRanHex } from "@/utils/helper";
//import { coins } from "@cosmjs/stargate";

import Web3 from 'web3'
const web3Obj = new Web3();

const md5 = require('md5');
const chainId = process.env.VUE_APP_UPTICK_CHAIN_ID;
const url = process.env.VUE_APP_UPTICK_URL;
const rest = process.env.VUE_APP_UPTICK_REST

export async function getMyBalance() {
    let account = await getAccountInfo();

    let client = await wasmClient();
    let balance = await client.getBalance(account.bech32Address, "auptick");

    let amt = web3Obj.utils.fromWei(balance["amount"],"ether");
    balance["format"] = parseFloat(amt).toFixed(2);
    let mount = balance.format;

    localStorage.setItem("key_balance", mount);
    return balance;
}

export async function issueDenomAndMint(
    orgName,
    sender,
    recipient,
    uri,
    data,
    amount,
    fee,
    adminAddress,
    // orgName, uri, data, count, adminAddres, amount
) {
    fee = web3Obj.utils.toWei(fee.toString());
    let account = await getAccountInfo();

    let name = getDenomName(orgName, account.bech32Address);
    let denomId = getDenomId(name);

    const msgs = [];
    let msg = {
        typeUrl: "/uptick.collection.v1.MsgIssueDenom",
        value: [denomId, name, getDenomSchema(), account.bech32Address, data, false, false]
    }
    msgs.push(msg);

    const mintIds = [];
    for (let i = 0; i < amount; i++) {
        let mintId = getNftId();
        mintIds.push(mintId);
        msg = {
            typeUrl: "/uptick.collection.v1.MsgMintNFT",
            value: [mintId, denomId, name, uri, data, account.bech32Address, account.bech32Address]
        }
        msgs.push(msg);
    }
    addSendMsg(msgs, account.bech32Address, adminAddress, fee);

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, data);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)

    return {
        txInfo: result,
        denomInfo: msgs
        // transactionHash: result.transactionHash,
        // mintIds: mintIds,
        // denomId: denomId,
        // name: name
    };
}

export async function transferNft(
    nftIds, denomId, name, recipient, fee, adminAddress, memo
    // nftIds, denomId, name, uri, data, recipient, adminAddres, amount
) {
    
    if(fee > 0){
        fee = web3Obj.utils.toWei(fee).toString();
    }
    let account = await getAccountInfo();

    const msgs = [];
    for (let i = 0; i < nftIds.length; i++) {
        let msg = {
            typeUrl: "/uptick.collection.v1.MsgTransferNFT",
            value: [nftIds[i], denomId, name, "", memo, account.bech32Address, recipient]
        }
        msgs.push(msg);
    }

    //xxl ##04 add fee must > 0
    if(fee > 0){
        addSendMsg(msgs, account.bech32Address, adminAddress, fee);
    }

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, memo);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)
    console.log("lj68 transferNft result", result);
    return result;
}

export async function orderPay(fee, recipient, memo) {
    fee = web3Obj.utils.toWei(fee).toString();
    let account = await getAccountInfo();

    let msgs = [];
    addSendMsg(msgs, account.bech32Address, recipient, fee);

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, memo);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)
    return result;
}

// export default {
//     getMyBalance, issueDenomAndMint, transferNft, orderPay
// }

async function signingStargateClient() {
    const offlineSigner = window.getOfflineSigner(chainId);
    let client = await SigningStargateClient.connectWithSigner(url, offlineSigner);
    return client;
}

async function wasmClient() {
    let client = await CosmWasmClient.connect(url);
    return client;
}

async function sendMsgsTx(address, msgs, amount, data) {
    let baseGas = process.env.VUE_APP_IRIS_BASE_GAS
    const fee = {
        amount: [{
            denom: 'auptick',
            amount: amount,
        }],
        gas: baseGas,
    };
    let client = await signingStargateClient();
    const result = await client.sendMsgsTx(address, msgs, fee, data);
    return result;
}

function addSendMsg(msgs, fromAddress, adminAddres, amount) {
    const coins_amount = coins(amount, "auptick");

    let msg = {
        typeUrl: "/cosmos.bank.v1beta1.MsgSend",
        value: {
            fromAddress: fromAddress,
            toAddress: adminAddres,
            amount: coins_amount,
        }
    }
    msgs.push(msg);
}

export async function getAccountInfo() {
    try {
        
        // keplr 检测
        await window.keplr.enable(chainId);
        // get account
        const account = await window.keplr.getKey(chainId);
        return account;
    } catch (error) {
        console.log(error);
    }
}

function getNftId() {
    let preFix = process.env.VUE_APP_AUPTICK_PREFIX;
    return preFix + getRanHex(16);
}

function getDenomName(name, address) {
    let denomName = name + "_" + Math.floor(Date.now() / 1000) + "_" + address.substr(address.length - 4);
    return denomName;
}

function getDenomId(name) {
    let preFix = process.env.VUE_APP_AUPTICK_PREFIX;
    return preFix + md5(name);
}

function getDenomSchema() {
    let json = { "type": "/auptick.souvenirCard" };
    return JSON.stringify(json);
}


export async function initWallet() {

    window.keplr.experimentalSuggestChain({
      chainId:chainId,
      chainName:'Uptick-testnet-0.1',
      rpc:url,
      rest:rest,
      stakeCurrency:{
          coinDenom:'uptick',
          coinMinimalDenom:'auptick',
          coinDecimals:18
      },
      bip44:{
          coinType: 60,
      },
      bech32Config: {
        bech32PrefixAccAddr: 'uptick',
        bech32PrefixAccPub: 'uptickpub',
        bech32PrefixValAddr: 'uptickvaloper',
        bech32PrefixValPub: 'uptickvaloperpub',
        bech32PrefixConsAddr: 'uptickvalcons',
        bech32PrefixConsPub: 'uptickvalconspub',
      },
      currencies:[
          {
            coinDenom: 'UPTICK',
            coinMinimalDenom: 'auptick',
            coinDecimals: 18,
          }
      ],
      feeCurrencies:[
          {
            coinDenom: 'UPTICK',
            coinMinimalDenom: 'auptick',
            coinDecimals: 18,
          }
      ],
      coinType: 60,
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.04,
      }
  })


}