import Vue from "vue";

import {
    getMyBalance as uptickevm_getMyBalance,
    transfer as uptickevm_transfer,
    transferFrom as uptickevm_transferFrom,
    saveApproveWithArray as uptickevm_saveApproveWithArray,
    revokeApprovesWithArray as uptickevm_revokeApprovesWithArray,
    orderPay as uptickevm_orderPay,
    getAccountInfo as uptickevm_getAccountInfo,
    getUptickAddress as uptickevm_getUptickAddress,
    setContractAddress as uptickevm_setContractAddress,
} from '@/metaMask/evm/index';



import chain_iris_w from "@/assets/icons/chain_iris_w.png";
import chain_uptick_w from "@/assets/icons/chain_uptick_w.png";

let chainName = "UPTICK EVM";

export function wallet() {
    let wt = {};

    if(chainName == "UPTICK EVM") {
        wt = {
            getMyBalance: uptickevm_getMyBalance,
            transfer: uptickevm_transfer,
            transferFrom: uptickevm_transferFrom,
            saveApproveWithArray: uptickevm_saveApproveWithArray,
            revokeApprovesWithArray: uptickevm_revokeApprovesWithArray,
            orderPay: uptickevm_orderPay,
            getAccountInfo: uptickevm_getAccountInfo,
            getUptickAddress: uptickevm_getUptickAddress,
            setContractAddress: uptickevm_setContractAddress
        };
    } else if(chainName == "Loopring") {
        wt = {
            // getMyBalance: handler.getUserBalances,
            // getAccountInfo: handler.getAccount,
        };
    }
    return wt;
};

export function init(chain) {
    if(chain) chainName = chain;
    Vue.prototype.$chainName = chain;
    let unit = chain=="IRIS" ? "IRIS":
               chain=="UPTICK" ? "UPTICK":
               chain=="UPTICK EVM" ? "UPTICK":
               chain=="Loopring" ? "LRC":
               "";
    Vue.prototype.$walletUnit = unit;

    let img = chain=="IRIS" ? chain_iris_w:
              chain=="UPTICK" ? chain_uptick_w:
              chain=="UPTICK EVM" ? chain_uptick_w:
              "";
    Vue.prototype.$walletIcon = img;

    Vue.prototype.$wallet = wallet(chain);
    Vue.filter("$unitFormat", function(val) {
        return val.replaceAll("$UNIT", unit);
    });
}
