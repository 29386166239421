import Vue from "vue";
import $store from "../store";

import { LRCHandler } from '@/loopring/handler.js';
let handler;

import { getApiKeyCache } from "@/walletConnect/index";

(function () {
    let json = window.localStorage.getItem("key_user");
    if(json) {
        let data = JSON.parse(json);
        if (window.ethereum && data.walletType == 2) {
            addNetwork();
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
    }
})();

async function handleAccountsChanged(accounts) {
    if (accounts.length == 0) return;
    sessionStorage.removeItem("KEY_LRCAccount");

    let address = accounts[0];
    let did = address;
    $store.commit('SET_DID', did.toLowerCase());
    
    localStorage.setItem("key_user", JSON.stringify({ user: true, did: $store.state.did, bscAddress: "", walletType: 2 }));
    location.reload();
}

async function addNetwork() {
    try {
        let env = process.env.VUE_APP_ENVIRONMENT;
        let chainId = '0x5';
        if(env == "dev") {
            chainId = '0x5';
        } else if(env == "pro") {
            chainId = '0x1';
        }
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }]
        });
    }
    catch (error) {
        console.log(error);
    }
}

export const getLogged = async () => {
    console.log("wxl --- getLogged")

    let accountInfo = await handler.getAccountInfoSession();
    console.log("wxl --- 1111",accountInfo)
    if(accountInfo.accountId > 0) {
        return true;
    }
    return false;
}

export function create() {
    return new Promise((resolve) => {
        handler = new LRCHandler(res => {
            resolve(res);
        });
        Vue.prototype.LRCHandler = handler;
    });
}

export function init() {
    let json = window.localStorage.getItem("key_user");
    if(json) {
      
        let data = JSON.parse(json);
        window.walletType = data.walletType;
        
        handler = new LRCHandler();
        Vue.prototype.LRCHandler = handler;
        Vue.prototype.getLogged = getLogged;

        // if(data.walletType == 2) {
        //     handler = new LRCHandler(5);
        //     Vue.prototype.LRCHandler = handler;
        //     Vue.prototype.getLogged = getLogged;
        // }
        // else if(data.walletType == 3) {
        //     Vue.prototype.getLogged = getApiKeyCache;
        // }
    }else{
        handler = new LRCHandler();
        Vue.prototype.LRCHandler = handler;
        Vue.prototype.getLogged = getLogged;
    }
}


// const permissions = await window.ethereum.request({
//     method: 'wallet_requestPermissions',
//     params: [{
//         eth_accounts: {},
//     }]
// });
