<template>
  <v-list nav dense color="#fff">
    <v-list-item exact v-for="item in userRuter" :key="item.value" color="#fff"
      @click="change(item.value)">
      <v-list-item-content>
        <v-list-item-title  v-if="item.label == 'Claim LRC Airdrop'" style="color:#ff7200">
          {{ item.label }}
          <template v-if="item.value === 'Message' && newMsgCount > 0">
            <span class="star"></span>
          </template>
        </v-list-item-title>
        <v-list-item-title v-else >
          {{ item.label }}
          <template v-if="item.value === 'Message' && newMsgCount > 0">
            <span class="star"></span>
          </template>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="js">
import api from "@/api"
import { killSession  } from "@/walletConnect";

export default {
  name: 'UserCenterNav',
  props: {},
  data: function () {
    return {
     
      newMsgCount: 0,
	    userRuter: [
	      { label: this.$t('headerUserCenterNavMyHomePage'), value: 'PersonalPage' },
	      { label: this.$t('headerUserCenterNavEditProfile'), value: 'MyInfo' },
	       { label: this.$t('scroe_task_center'), value: 'PointsCenter'},
	       { label: this.$t('headerUserCenterNavMyFavorites'), value: 'Collect'},
	      { label: this.$t('headerUserCenterNavMyOrders'), value: 'MyOrders' },
	      { label: "My Loopring", value: 'MyLoopring' },
	       { label: this.$t('avatarAvatarInfoFans'), value: 'Fans'},
	       { label: this.$t('fansSubscrible'), value: 'Follows'},
	       { label: this.$t('messageMessage'), value: 'Message'},
        { label: this.$t('headerUserCenterNavSetting'), value: 'Setting'},
	      { label: this.$t('headerUserCenterNavdisConnect'), value: 'DisConnect' },
	  
	    ],
	
		
    };
  },
  computed: {



  },
  async mounted() {
    

    window.eventBus.$on('LangChange', this.onLangChange);

    if (this.$store.state.did != null) {



      let param = { address: this.$store.state.did };
      let res = await api.home.loopringUserInfo(param);
      let airdropValue = res.data.airdropValue;


      //判断是否可以领取空投
      if(Number(airdropValue) > 0){
        this.userRuter.splice(0, 0, { label: 'Claim LRC Airdrop', value: 'claim'});
      }

    }

     let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did});
     this.newMsgCount = res2.data;

  
     window.eventBus.$on("ViewMessage", async () => {
         let res3 = await api.home.isNewMessage({userAddress:this.$store.state.did});
         this.newMsgCount = res3.data;
     });
  },
  methods: {
    change: function (v) {
      this.$gtag.event('菜单栏-'+ v, { 'event_category': 'Click', 'event_label': v })
      if (v == "DisConnect") {
        localStorage.clear();
        sessionStorage.clear();
        killSession();
        this.$store.commit('SET_DID', "");
        window.bscAddress = "";
        this.$router.push({ name: 'Home' })
        window.location.reload();
      } else if (v == "MyLoopring") {
        window.open("https://loopring.io/#/layer2/");
      } else if (v == 'claim'){
        console.log("wxwdw")
      } else {
        this.$router.push({ name: v });
      }
      this.$emit('change',v)
    },

  }
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  background: #270645;

  .v-list-item__title {
    color: #fff;

  }
}

.star {
  position: relative;

  &::after {
    content: ".";
    font-size: 60px;
    color: red;
    position: absolute;
    right: -20px;
    top: -15px;
  }
}
</style>
