import {
    connect
} from "./common";

import {
    abi
} from "../artifact/ERC721Platform.json";

const base = require('./base');

//xxl todo get from .evn
let contractAddress = "0xd3e379f75d08ba91f632b363f021ceda01d94984"

export function setContractAddress(platformAddress) {
    if(platformAddress) {
        contractAddress = platformAddress;
    }
}

export async function transfer(toAddress, tokenId, value) {
    const account = await base.getAccounts();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }

    let gasSetting = await base.getGasPriceAndGasLimit();
    let rep = await contract.transfer(toAddress, tokenId, value, {
        value: value, gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit
    });
    return rep;
}

export async function saveApproveWithArray(tokenArr, value, fee) {
    const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let gasSetting = await base.getGasPriceAndGasLimit();
    let rep = await contract.saveApproveWithArray(fromAddress, tokenArr, value, {
        value: fee, gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit
    });
    return rep;
}

export async function revokeApprovesWithArray(tokenArr) {
    const account = await base.getAccounts();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
    let gasSetting = await base.getGasPriceAndGasLimit();
    let rep = await contract.revokeApprovesWithArray(tokenArr,
        { gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit }
    );
    return rep;
}
