<template>
  <v-tabs class="rd" hide-slider exact>
    <v-tab :ripple="false" :to="{ name: 'Home'}" @click="navClick('Home')">{{ $t('headerNavHome') }}</v-tab>
    <v-tab :ripple="false" :to="{ name: 'LaunchPad'}" @click="navClick('LaunchPad')"> LaunchPad  </v-tab>
    <v-tab :ripple="false" :to="{ name: 'Marketplace'}" @click="navClick('Marketplace')"> Marketplace</v-tab>
    <!-- <v-tab class="icon" :ripple="false" :to="{name:'Promo'}">{{ $t('headerNavPromo') }}</v-tab> -->

  </v-tabs>
</template>

<script lang="js">

export default {
  name: 'Nav',
  inheritAttrs: false,
  data: function() {
    return {};
  },
  methods:{
    navClick(name){
       this.$gtag.event('导航切换-'+name, { 'event_category': 'Click', 'event_label': name })
      console.log("navClick",name);
    },
  }
};
</script>

<style lang="scss" scoped>
.v-tabs.rd {
  width: unset;
  flex: 0 1 auto;
  &::v-deep .v-item-group {
    flex: 0 1 auto;
    width: unset;
    .v-slide-group__content, .v-slide-group__wrapper {
      flex: 0 1 auto;
    }
  }
 
  .v-tab {
    padding-right: 4px;
    padding-left: 25px;
    color: #1d42ff;
  }
  .img{
    margin-left: -18px;
    margin-top:12px;
  }
  
}
</style>
