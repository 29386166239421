import Encrypt from 'encryptlong'
const Base64 = require('js-base64').Base64

//公钥
var signPrivateKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCJSqtslCHtxOlEfEacw0OmYtbLAs5R+AvPCAVeF8ccPDJZtJU2UpABxg0l/10CFDVYUlI/FnVnhl8eOfDnDMATgVGGwZMI+nB7h09RynshQjOfPRPqgNUSVkMkfCdbz8JdVyC8nnZvMbbRYYpt2NB0G9XUqgSEWwBJ1MP56QNtcwIDAQAB';


export default {
    encrypt: function (data) {
        let encrypt = new Encrypt();
        encrypt.setPublicKey(signPrivateKey);
        let info = encrypt.encryptLong(JSON.stringify(data))
        const exStr = Base64.encode(info)//加密
        return exStr
    },
    
}