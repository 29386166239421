import
service, { $postBodyJson }
    from '@/utils/request';
import { BASE_URL,BASE_URL_S3 } from '../config';
import rsa from "@/utils/RSAEncrypt.js";

// let BASE_URL2="http://13.213.149.227:7091/api/1.0";
export function loopringUserInfo(params) {
    return service.post(`${BASE_URL}/user/info.json`,
        transObjToParamStr2(params)
    );
}
// cerfified/create.do
export function cerfifiedApply(params) {
    return service.post(`${BASE_URL}/cerfified/create.do`, 
        transObjToParamStr2(params)
    )
}
export function loopringUserSave(params) {
    return service.post(`${BASE_URL}/user/save.do`,
        transObjToParamStr2(params,true)
    );
}
//一键读取
export function readAllMessage(params) {
    return service.post(`${BASE_URL}/message/read`, 
        transObjToParamStr2(params)
    )
}

// 领取空投
export function receiveAirdrop(params) {
    return service.post(`${BASE_URL}/airdrop/receive.do`,
        transObjToParamStr2(params,true)
    );
}

export function nftDeploy(params) {
    return service.post(`${BASE_URL}/nft/deploy.do`,
        transObjToParamStr2(params,true)
    );
}

export function nftMint(params) {
    return service.post(`${BASE_URL}/nft/mint.do`,
        transObjToParamStr2(params,true)
    );
}

export function nftContractList(params) {
    return service.post(`${BASE_URL}/nft/contractList.json`,
        transObjToParamStr2(params)
    );
}

// export function nftCreated(params) {
//     return service.post(`${BASE_URL}/nft/getCreated.json`,
//         transObjToParamStr2(params)
//     );
// }

export function nftList(params) {
    return service.post(`${BASE_URL}/nft/list.do`,
        transObjToParamStr2(params)
    );
}

// 获取系统信息
export function sysMessage(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/message/sysMsg`, 
        transObjToParamStr2(params)
    )
}
// 作品投诉
export function nftComplaint(params) {
    return service.post(`${BASE_URL}/complaint/create.do`, 
        transObjToParamStr2(params)
    )
}
// 作品申诉
export function nftAppeal(params) {
   return service.post(`${BASE_URL}/complaint/appeal.do`,
       transObjToParamStr2(params)
   )
}

//创作者投诉
export function accountComplaint(params) {
    return service.post(`${BASE_URL}/complaint/creator/create.do`, 
        transObjToParamStr2(params)
    )
}

// 监测是否可作者申诉
export function checkComplaint(params) {
    return service.post(`${BASE_URL}/complaint/creator/check.do`, 
        transObjToParamStr2(params)
    )
}

// 创作者申诉
export function accountAppeal(params) {
    return service.post(`${BASE_URL}/complaint/creator/appeal.do`, 
        transObjToParamStr2(params)
    )
}
export function nftDeList(params) {
    return service.post(`${BASE_URL}/nft/deList.do`,
        transObjToParamStr2(params)
    );
}

export function nftMyNFTList(params) {
    return service.post(`${BASE_URL}/nft/myNFTList.json`,
        transObjToParamStr2(params)
    );
}
// Query nft market info
export function marketNftInfo(params) {
    return service.post(`${BASE_URL}/market/nftInfo.json`,
        transObjToParamStr2(params)
    );
}

export function nftTrade(params) {
    return service.post(`${BASE_URL}/nft/trade.do`,
        transObjToParamStr2(params)
    );
}

function transObjToParamStr2(object,isSign) {
    if(isSign){
        let sign =  rsa.encrypt(object)
        object = {sign:sign}
    }
    let arr = [];
    for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            arr.push(`${key}=${element}`);
        }
    } 
    return arr.join('&');
}

function setEvmParams(object) {
    let key = localStorage.getItem('KEY_CHAIN');
    if (key == "UPTICK EVM") {
        object.chainType = "EVM_UPTICK";
    }
}


//uptickLoopring jiekou

export function getMarketnftList(params) {
    return service.post(`${BASE_URL}/market/list.json`,
        transObjToParamStr2(params)
    )
}
///api/1.0/nft/getRecommendContract.json
export function getRecommendList(params) {
    return service.post(`${BASE_URL}/nft/getRecommendContract.json`,
        transObjToParamStr2(params)
    )
}

export function getLaunchList(params) {
    return service.post(`${BASE_URL}/market/getFirstPublish.json`,
        transObjToParamStr2(params)
    )
}

export function getPersonnftList(params) {
    return service.post(`${BASE_URL}/homepage/token.json`,
        transObjToParamStr2(params)
    )
}

export function getPersoncollectionList(params) {
    return service.post(`${BASE_URL}/homepage/collection.json`,
        transObjToParamStr2(params)
    )
}

//resale  
export function getMarketList(params) {
    return service.post(`${BASE_URL}/market/getMarketplace.json`,
        transObjToParamStr2(params)
    )
}

export function getResaleNftInfo(params) {
    return service.post(`${BASE_URL}/fleaMarket/nftInfo.json`,
        transObjToParamStr2(params)
    )
}

export function NftSingleTrade(params) {
    return service.post(`${BASE_URL}/nft/singleTrade.do`,
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/nft/getMyTokenId.json
export function getNftOwn(params) {
    return service.post(`${BASE_URL}/nft/getMyTokenId.json`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/nft/getMyContract.json
export function getOwnByCollection(params) {
    return service.post(`${BASE_URL}/nft/getMyContract.json`,
        transObjToParamStr2(params)
    )
}

export function getOwnByCreator(params) {
    return service.post(`${BASE_URL}/nft/getOwnByCreator.json`,
        transObjToParamStr2(params)
    )
}

export function nftTokenInfo(params) {
    return service.post(`${BASE_URL}/nft/tokenInfo.json`,
        transObjToParamStr2(params)
    )
}


export function assetHistory(params) {
    return service.post(`${BASE_URL}/nft/history.json`,
        transObjToParamStr2(params)
    )
}

//POST /api/1.0/auction/bidHistory
export function bidHistory(params) {
    return service.post(`${BASE_URL}/auction/bidHistory`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/auction/auctionDetail/bid
export function auctionBid(params) {
    return service.post(`${BASE_URL}/auction/auctionDetail/bid`,
        transObjToParamStr2(params)
    )
}
export function nftSingleList(params) {
    return service.post(`${BASE_URL}/nft/singleList.do`,
        transObjToParamStr2(params)
    )
}

export function nftSingleDeList(params) {
    return service.post(`${BASE_URL}/nft/singleDeList.do`,
        transObjToParamStr2(params)
    )
}

export function orderList(params) {
    return service.post(`${BASE_URL}/order/list.json`,
        transObjToParamStr2(params)
    )
}
export function saleOrderList(params) {
    return service.post(`${BASE_URL}/order/sale/list.json`,
        transObjToParamStr2(params)
    )
}

export function collectionInfo(params) {
    return service.post(`${BASE_URL}/nft/collectionInfo.json`,
        transObjToParamStr2(params)
    )
}

export function chainToken(params) {
    return service.post(`${BASE_URL}/data/token.json`,
        transObjToParamStr2(params)
    )
}

export function nftBurn(params) {
    return service.post(`${BASE_URL}/nft/burn.do`,
        transObjToParamStr2(params)
    )
}

export function nftTransfer(params) {
    return service.post(`${BASE_URL}/nft/transfer.do`,
        transObjToParamStr2(params)
    )
}

export function nftSingleTransfer(params) {
    return service.post(`${BASE_URL}/nft/singleTransfer.do`,
        transObjToParamStr2(params)
    )
}
export function getNftTokenId(params) {
    return service.post(`${BASE_URL}/nft/getTokenId.json`,
        transObjToParamStr2(params)
    )
}

export function orderStatements(params) {
    return service.post(`${BASE_URL}/order/statements.json`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/add.do
export function addFollow(params) {
    return service.post(`${BASE_URL}/fans/add.do`,
        transObjToParamStr2(params,true)
    )
}
// POST /api/1.0/fans/remove.do
export function removeFollow(params) {
    return service.post(`${BASE_URL}/fans/remove.do`,
        transObjToParamStr2(params,true)
    )
}
//POST /api/1.0/fans/isFans.do
export function isFollow(params) {
    return service.post(`${BASE_URL}/fans/isFans.do`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/myFocus.page
export function myFocus(params) {
    return service.post(`${BASE_URL}/fans/myFocus.page`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/fans/myFans.page
export function myFans(params) {
    return service.post(`${BASE_URL}/fans/myFans.page`,
        transObjToParamStr2(params)
    )
}
// POST /api/1.0/follow/follow.do
export function followDo(params) {
    return service.post(`${BASE_URL}/follow/follow.do`,
        transObjToParamStr2(params,true)
    )
}
// POST /api/1.0/follow/isFollow.info
export function isCollect(params) {
    return service.post(`${BASE_URL}/follow/isFollow.info`,
        transObjToParamStr2(params)
    )
}
//POST /api/1.0/follow/list.page
export function followList(params) {
    return service.post(`${BASE_URL}/follow/list.page`,
        transObjToParamStr2(params)
    )
}
//留言、回复记录（指定好友）POST /api/1.0/words/record.page
export function messageRecord(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/record.page`, 
    transObjToParamStr2(params)
    )
}
//POST /api/1.0/words/add.do
export function message(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/words/add.do`, 
    transObjToParamStr2(params)
    )
}
// POST /api/1.0/words/list.page
export function listRecord(params) {
    return service.post(`${BASE_URL}/words/list.page`, 
    transObjToParamStr2(params)
    )
}
//查询群发记录 POST /api/1.0/words/group/record.page
export function messageRecordGroup(params) {
    return service.post(`${BASE_URL}/words/group/record.page`, 
        transObjToParamStr2(params)
    )
}
//添加群发记录
export function messageGroup(params) {
    return service.post(`${BASE_URL}/words/group/add.do`, 
        transObjToParamStr2(params)
    )
}
// 检查是否有留言 ,返回新消息条数，页面显示massage，以及头像小红点 POST /api/1.0/words/noRead.json
export function isNewMessage(params) {
    return service.post(`${BASE_URL}/words/noRead.json`, 
        transObjToParamStr2(params)
    )
}

export function scoreRecord(params) {
    return service.post(`${BASE_URL}/score/record.page`, 
        transObjToParamStr2(params)
    )
}
//  /api/1.0/user/updateData.do 
export function loopringUpdateDate(params) {
    return service.post(`${BASE_URL}/user/updateData.do`, 
        transObjToParamStr2(params)
    )
}

// POST /api/1.0/auction/lastBidPrice
export function currentBid(params) {
    return service.post(`${BASE_URL}/auction/lastBidPrice`, 
        transObjToParamStr2(params)
    )
}

// POST 
export function getStorageId(params) {
    return service.post(`${BASE_URL}/auction/checkStorageId`, 
        transObjToParamStr2(params)
    )
}
// POST 
export function getSystemParams(params) {
    return service.post(`${BASE_URL}/data/dataMap.json`, 
        transObjToParamStr2(params)
    )
}
///file/upload/json
export function updateCollection(params) {
    return service.post(`${BASE_URL_S3}/file/upload/json`, 
        transObjToParamStr2(params)
    )
}

// 奖励积分
export function scoreReward(params) {
    console.log(BASE_URL)
    return service.post(`${BASE_URL}/score/mining/reward.json`, 
        transObjToParamStr2(params)
    )
}

// score/exchangeInfo 圈存总数
export function scoreTotalInfo(params) {
    return service.post(`${BASE_URL}/score/exchangeInfo`, 
        transObjToParamStr2(params)
    )
}
//score/exchangeList 圈存总记录
export function scoreList(params) {
    return service.post(`${BASE_URL}/score/exchangeList`, 
        transObjToParamStr2(params)
    )
}
// 积分兑换
export function scoreExchange(params) {
    return service.post(`${BASE_URL}/score/exchange.do`, 
        transObjToParamStr2(params,true)
    )
}
// 获取Uptick地址
export function getUptickAddress(params) {
    return service.post(`${BASE_URL}/user/addressToUptick`, 
        transObjToParamStr2(params)
    )
}
// airdrop/show/nft 获取官方NFT推荐卡片
export function getOfficialList() {
    return service.post(`${BASE_URL}/airdrop/show/nft`,
       
    )
}
// 权益卡领取记录 airdrop/sysList
export function claimList(params) {
  
    return service.post(`${BASE_URL}/airdrop/loopring/info `, 
        transObjToParamStr2(params)
    )
}
// 领取积分
export function claimPoint(params) {
  
    return service.post(`${BASE_URL}/airdrop/loopring/receive `, 
        transObjToParamStr2(params)
    )
}
export function resolveEthereumEns(params) {
    return service.post("https://api3.loopring.io/api/v2/delegator/resolveEthereumEns", params);
}
export function reverseAddrEns(address) {
	debugger
    return service.get("https://api.loopring.network/api/wallet/v3/reverseAddrEns?address="+address);
}