<template>
   <div>
      <v-menu v-model="show" :attach="$el" right offset-y nudge-bottom="10px">
         <template v-slot:activator="{ on, attrs, value }">
            <Chip v-if="!isMobile" v-bind="attrs" v-on="on" pill class="pl-0">
               <!-- [todo: size 40] -->
               <v-avatar :size="40">
                  <v-img :src="usersrc" />
               </v-avatar>
               <span class="ml-4 user" :class="{ star: newMsgCount > 0 }">{{ userName }}</span>
               <v-icon class="ml-3" color="primary" v-if="value">mdi-menu-up
               </v-icon>
               <v-icon class="ml-3" color="primary" v-else>mdi-menu-down</v-icon>
            </Chip>
            <Chip v-else v-bind="attrs" class="pa-0" @click="avatarClick">
               <v-avatar :size="48" class="mobile-avatar">
                  <v-img :src="usersrc" />
               </v-avatar>
            </Chip>
         </template>
         <UserCenterNav @change="change" />
      </v-menu>
      <v-dialog v-model="openAppeal">
         <accountAppeal @getOpen="Open"></accountAppeal>
      </v-dialog>

      <v-dialog v-model="disAppeal">
         <v-card>
            <img class="card-close" width="31px" height="31px" @click="disAppeal = false" src="@/assets/btn_close.png"
               alt="" />
            <div style="width: 500px; height: 300px;">
               <!-- <img alt="logo" src="/src/assets/image/raredroplogo.ico" height="43px" width="auto" class="logo"> -->
               <div class="text">
                  <h1>用户不可以申诉</h1>
               </div>
            </div>
         </v-card>
      </v-dialog>
    <v-dialog v-model="Banprompt">
      <claimAirdrop v-if="Banprompt"  @getOpenBan="openBanprompt"></claimAirdrop>
    </v-dialog>
   </div>
</template>

<script lang="js">
import Chip from "@/components/Chip.vue";
import UserCenterNav from "./UserCenterNav.vue";
import AccountAppeal from "@/components/popup/accountAppeal.vue";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import ClaimAirdrop from "@/components/popup/ClaimAirdrop.vue";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";


export default {
   name: "UserCenterSelect",
   components: {
      Chip,
      UserCenterNav,
      AccountAppeal,
      ClaimAirdrop
   },
   props: {},
   data: function () {
      return {
         Banprompt:false,
         openAppeal: false,
         disAppeal: false,
         show: false,
         userName: "",
         usersrc: "",
         defaultImg,
         newMsgCount: 0
      };
   },
   computed: {
      isMobile: function () {
         return this.$vuetify.breakpoint.mobile;
      },
   },
   methods: {
      async init() {
         if (this.$store.state.did != null) {
            let param = { address: this.$store.state.did };
            let res = await api.home.loopringUserInfo(param);
            if (res.data != null) {
               window.sessionStorage.setItem("UserData", JSON.stringify(res.data));

               if (res.data && res.data.profilePhoto) {
                  this.userName = res.data.name;
                  this.imgUrl = res.data.profilePhoto;
                  this.usersrc = await getFileSrc(AVATAR_KEY, this.imgUrl);
               } else {
                  this.usersrc = defaultImg;
                  this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
               }

            } else {
               this.usersrc = defaultImg;
               this.userName = `${this.$store.state.did.substr(0, 3)}...${this.$store.state.did.substr(-3, 3)}`;
               this.$router.push({
                  name: "MyInfo"
               });
            }
            
         }
      },
      async change(v) {
         if (v.toLowerCase() == "appeal") {
            this.openAppeal = true;
         } else if (v.toLowerCase() == "appealed") {
            this.disAppeal = true;
         }else if(v.toLowerCase() == "claim") {
            this.Banprompt  = true
          }

         this.show = false;
      },
    openBanprompt(e){
         this.Banprompt = e
      },
      Open(e) {
         this.openAppeal = e;
      },

      avatarClick() {
         this.$emit("click");
      }
   },
   async mounted() {
      window.eventBus.$on("MidifyUser", this.init);
      this.init();
   //     let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did});
   //    this.newMsgCount = res2.data;


   //   // 有新消息进行弹窗
   //   if(this.newMsgCount >0){
   //      let params={
   //      pageSize: 10,
   //      pageNumber: 1,
   //      owner:this.$store.state.did,
   //      isRead:false
   //    };
   //    let res = await api.home.sysMessage(params)
   //    console.log("wxl --- sysMessage",res)
   //    if(res.data){
   //       let list = res.data.list
   //       for (let index = 0; index < list.length; index++) {
   //         setTimeout(() => {
   //            this.$mtip({
   //              title:list[index].pushContent,
   //            });
   //         }, 1000);
           
   //       }
   //    }
   //    this.newMsgCount = 0
   //   }

      window.eventBus.$on("ViewMessage", async () => {
          let res3 = await api.home.isNewMessage({userAddress:this.$store.state.did});
          this.newMsgCount = res3.data;
      });
      
   },
};
</script>

<style lang="scss" scoped>
// .v-menu__content{
//   position:fixed !important;
//   top: 80px !important;
// }
.user {
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width: 55px;
}

.card-close {
   display: block;
   position: absolute;
   right: 10px;
   top: 10px;
   background-color: #fff;
   border-radius: 50%;
   cursor: pointer;
}

.logo {
   margin: 20px;
}

.text {
   display: flex;
   height: 300px;
   justify-content: center;
   align-items: center;
   letter-spacing: 0.4rem;
}

.star::before {
   content: ".";
   font-size: 60px;
   color: red;
   position: absolute;
   left: 30px;
   top: -15px;
}

.mobile-avatar {
   width: 48px !important;
   height: 48px !important;
}
</style>
