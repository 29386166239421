<template>
  <div class="load" id="load">
    <div class="dlg">
      <img
        class="close"
        @click="closeDialog"
        src="@/assets/icons/btn_close.png"
        alt=""
      />
      <div class="title1">
        {{ text1 }}
      </div>
      <div class="img">
        <img src="@/assets/loading.gif" alt="" />
      </div>
      <div class="title" :class="{ error: message }">
        <!-- {{ $t('waitingForSignature') }}... -->
        {{ msgText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnlockDlg",
  props: {
    parent: Object,
    message: String,
  },
  data: function () {
    return {
      text1: "Unlock Account",
      msgText: "Waiting for signature",
    };
  },
  async mounted() {
    if (this.message) this.msgText = this.message;

    sessionStorage.removeItem("KEY_exchangeInfo");
    sessionStorage.removeItem("KEY_accInfo");
    sessionStorage.removeItem("KEY_keySeed");
    sessionStorage.removeItem("KEY_eddsaKey");
    sessionStorage.removeItem("KEY_apiKey");
    sessionStorage.removeItem("KEY_activeFee");
    sessionStorage.removeItem("KEY_LRCAccount");
  },
  methods: {
    setText(txt) {
      this.text = txt;
    },
    async sign() {
      let accInfo = await this.LRCHandler.getAccountInfo();
      return accInfo;
    },
    closeDialog() {
      let dom = document.getElementById("load");
      dom.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
}
.load {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0;
  bottom: 0;
  z-index: 9999;
  // background-color: black;
  // opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  .dlg {
    width: 410px;
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .img {
      // margin-top: -100px;
      margin-top: 50px;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      margin-top: 20px;
      // color: white;
      font-size: 16px;
    }

    .title1 {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 30px;
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 31px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 0;
      cursor: pointer;
      border-radius: 50% !important;
      z-index: 99;
    }
  }
}

.dlg.mobile {
  width: 300px;
}
</style>
