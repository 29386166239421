<template>
  <v-list nav dense :light="false">
    <v-list-item-group mandatory color="#fff" v-if="type=='head'">
      <v-list-item :to="{ name: 'Home' }" @click="click">
        <v-list-item-content class="mobileNavTitle">
          <v-list-item-title v-text="$t('headerNavHome')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'LaunchPad' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('LaunchPad ')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Marketplace' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('Marketplace')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="user" :to="{ name: 'Create' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('Create')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

    <v-list-item-group mandatory color="#fff" v-if="type=='user'">
      <v-list-item :to="{ name: 'PersonalPage' }" @click="click">
        <v-list-item-content class="mobileNavTitle">
          <v-list-item-title v-text="$t('headerUserCenterNavMyHomePage')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'MyInfo' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('headerUserCenterNavEditProfile')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'PointsCenter' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('scroe_task_center')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="user" :to="{ name: 'Collect' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('headerUserCenterNavMyFavorites')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'MyOrders' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('headerUserCenterNavMyOrders')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="LoopringClick">
        <v-list-item-content>
          <v-list-item-title v-text="$t('My Loopring')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Fans' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('avatarAvatarInfoFans')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Follows' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('fansSubscrible')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Message' }" @click="click">
        <v-list-item-content>
          <v-list-item-title v-text="$t('messageMessage')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="disConnectClick">
        <v-list-item-content>
          <v-list-item-title v-text="$t('headerUserCenterNavdisConnect')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "DropSelectNav",
  props: {
    value: Boolean,
    type: String,
  },
  data: function () {
    return {
      user: false,
    };
  },
  mounted() {
    const json = localStorage.getItem("key_user");
    if (json) {
      const data = JSON.parse(json);
      this.user = data.user;
    }
  },
  methods: {
    click: function () {
      this.$emit("input", !this.value);
      console.log(",,,,,,,,,", !this.value);
    },
    LoopringClick() {
      window.open("https://loopring.io/#/layer2/");
      this.$emit("input");
    },
    disConnectClick() {
      localStorage.clear();
      sessionStorage.clear();

      this.$store.commit('SET_DID', "");
      window.bscAddress = "";
      this.$router.push({ name: 'Home' })
      window.location.reload();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-list-item .v-list-item__title {
  font-size: 1.3rem !important;
  line-height: 2.5rem !important;
}
.v-list-item.v-item--active {
  background: $primary;

  &::v-deep .v-list-item__title {
    color: #fff;
  }
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $primary;
}
</style>
